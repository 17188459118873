import React, {useEffect, useState} from 'react'
import axios from 'axios';
import {LinkContainer} from 'react-router-bootstrap'
import {Table, Button, Row, Col, Container} from 'react-bootstrap'
import { useSelector} from 'react-redux'
import Header from "../../components/Header";
import {baseurl} from "../../ApiEndpoint/Api";

const DpsList = () => {

    const userSignin = useSelector((state) => state.userSignin)
    const {userInfo} = userSignin

    if (!userInfo) {
        document.location.href = '/'
    }
    const config = {
        headers: {
            Authorization: 'Bearer ' + userInfo.token
        }
    }
    //
    const [dps, setDPS] = useState([])
    useEffect(() => {

        axios.get(`${baseurl}/api/dpslist`, config)
            .then(resp => {
                setDPS(resp.data)
                console.log(resp.data)

            })
    }, [])

    const [bal, setBal] = useState(0)
    async function seeTotalBal() {

        await axios.get(`${baseurl}/api/totalbal`, config)
            .then(resp =>
                {
                setBal(resp.data)
            }
            )
            .catch()

    }

    const url = `${baseurl}/api/dpslist/download`
    const download =(url)=>{
        const aTag = document.createElement('a')
        aTag.href=url
        aTag.setAttribute('download','dpslist.csv')
        document.body.appendChild(aTag)
        aTag.click()
        aTag.remove()
    }

    return (
        <>
            <Header/>
            <Container>

                <Row className='align-items-center'>
                    <Col>
                        <h1>DPS List</h1>

                    </Col>
                </Row>
                <Row>

                    <Col className='text-left'>


                    <Button className='my-3'

                    onClick={() => {download(url)}}
                    >
                            <i className='fas fa-download'></i> Download DPS-List
                        </Button>
                        <br/>

                        <LinkContainer to={'/getuser'}>
                            <Button className='my-3'>
                                <i className='fas fa-plus-circle'></i> Add DPS
                            </Button>
                        </LinkContainer>
                        <br/>

                    </Col>
                    <Col className='text-right'>
                        <h3 className='my-3'>Total Balance: {bal.accountBalance__sum} Tk</h3>

                        <button type="submit" className="addLoaneeButton"
                         onClick={seeTotalBal}
                        >
                        See
                        </button>

                    </Col>


                </Row>
                <Table

                    style={{padding: "10px 10px 5px 5px", margin: "10px 10px 10px 10px"}}>
                    <thead>
                    <tr>
                        <th>Serial no</th>
                        <th>Username</th>
                        <th>Contact</th>
                        <th>DPS Type</th>
                        <th>Somiti Type</th>
                        <th>Account Balance</th>
                        <th>Total Deposit</th>
                        <th>Deposit Made</th>
                        <th>Return Amount</th>
                        <th>Added By</th>
                        <th>Opening Time</th>

                    </tr>
                    </thead>
                    <tbody>
                    {dps.map((d) => (
                        <tr key={d.id} >

                        <td>{d.id}</td>
                            <td>{d.accountHolder.username}</td>
                            <td>{d.accountHolder.phone}</td>
                        <td>{d.dpsType}</td>
                            <td>{d.accountHolder.somiti_type}</td>
                        <td>{d.accountBalance}</td>
                        <td>{d.totalDep}</td>
                        <td>{d.depMade}</td>
                        <td>{d.returnBal}</td>
                        <td>{d.doneby}</td>
                        <td>{d.openingDate}</td>


                        <td>
                        <LinkContainer to={`/adddeposit/${d.id}`}>
                    <Button variant='light' className='btn-sm'>
                      <i className='fas fa-edit'></i>
                    </Button>
                  </LinkContainer>
                  {/*<LinkContainer to={`/paid/${loan.id}`}>*/}
                  {/*  <Button variant='light' className='btn-sm'>*/}
                  {/*    <i className='fas fa-edit'></i>*/}
                  {/*  </Button>*/}
                  {/*</LinkContainer>*/}
                  <Button
                    variant='danger'
                    className='btn-sm'

                  >
                    <i className='fas fa-trash'></i>
                  </Button>
                        </td>
                    </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>


        </>
    )
}

export default DpsList