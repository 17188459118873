import React from 'react'
import Header from "../components/Header";
import Footer from '../components/Footer';
import Content from '../components/Start/Content';
const Start = () => {
  return (
    <div>
       <Header/>
       <Content/>
      <Footer/>
    </div>
  )
}

export default Start