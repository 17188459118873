import React, {useEffect, useState} from 'react'
import axios from 'axios';
import {LinkContainer} from 'react-router-bootstrap'
import {Table, Button, Row, Col, Container} from 'react-bootstrap'
import { useSelector} from 'react-redux'
import Header from "../../components/Header";
import {baseurl} from "../../ApiEndpoint/Api";

const DpsTransactions = () => {

    const userSignin = useSelector((state) => state.userSignin)
    const {userInfo} = userSignin

    if (!userInfo) {
        document.location.href = '/'
    }
    const config = {
        headers: {
            Authorization: 'Bearer ' + userInfo.token
        }
    }
    //
    const [dpsTran, setDpsTran] = useState([])
    useEffect(() => {

        axios.get(`${baseurl}/api/transactions`, config)
            .then(resp => {
                setDpsTran(resp.data)
                console.log(resp.data)

            })
    }, [])
    //
    //

    const url = `${baseurl}/api/fdrlist/download`
    const download =(url)=>{
        const aTag = document.createElement('a')
        aTag.href=url
        aTag.setAttribute('download','fdrlist.csv')
        document.body.appendChild(aTag)
        aTag.click()
        aTag.remove()
    }

    return (
        <>
            <Header/>
            <Container>

                <Row className='align-items-center'>
                    <Col>
                        <h1>Transaction List</h1>

                    </Col>
                </Row>
                <Row>

                    <Col className='text-left'>


                        <Button className='my-3'

                         onClick={() => {download(url)}}
                        >
                            <i className='fas fa-download'></i> Download Transaction-List
                        </Button>
                        <br/>




                    </Col>
                </Row>
                <Table

                    style={{padding: "10px 10px 5px 5px", margin: "10px 10px 10px 10px"}}>
                    <thead>
                    <tr>
                        <th>Transaction ID</th>
                        <th>Transaction Amount</th>
                        <th>Transaction Type</th>
                        <th>Made By</th>
                        <th>DPS serial No</th>
                        <th>Transaction Time</th>

                    </tr>
                    </thead>
                    <tbody>
                    {dpsTran.map((dpsT) => (
                         <tr key={dpsT.id} >
                        <td>{dpsT.id}</td>
                        <td>{parseFloat(dpsT.transactionAmount).toFixed(2)}</td>
                        <td>{dpsT.transactiontype}</td>
                        <td>{dpsT.doneby}</td>
                        <td>{dpsT.dps}</td>
                        <td>{dpsT.transactionTime}</td>


                    </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>


        </>
    )
}

export default DpsTransactions