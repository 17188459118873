import React, {useState} from 'react'
import Header from "../../components/Header";
import './customer.css'
import { useSelector} from "react-redux";
import MessageBox from "../../components/MessageBox";
import axios from "axios";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {baseurl} from "../../ApiEndpoint/Api";
import {useParams} from "react-router-dom";
const Nomini = () => {

    const [nominiName, setNominiName] = useState('');
    const [nominiRel, setNominiRel] = useState('');
    const [nominiFname, setNominiFname] = useState('');
    const [nominiMname, setNominiMname] = useState('');
    const [nominidob, setNominiDOB] = useState('');
    const [nominiPic, setNominiPic] = useState('')
    const [nominiNidPic, setNominiNidPic] = useState('')

    const userSignin = useSelector((state) => state.userSignin)
    const {userInfo} = userSignin

    if (!userInfo) {
        document.location.href = '/'
    }
    const config = {
        headers: {
            Authorization: 'Bearer ' + userInfo.token
        }
    }
    const formData = new FormData();

    formData.append("nominiFname", nominiFname);
    formData.append("nominiMname", nominiMname);
    formData.append("nominidob", nominidob);
    formData.append("nominiPic", nominiPic);
    formData.append("nominiNidPic", nominiNidPic);
    formData.append("nominiName", nominiName);
    formData.append("nominiRel", nominiRel);
    const  user = useParams()
    const  loaneeID = user.id
    const  id = Number(loaneeID);


    const [message, setMessage] = useState('')
    const [error, setError] = useState('')

    async function submitHandler() {
        console.log(formData)
        await axios.put(`${baseurl}/api/addnomini/${id}`, formData, config)

            .then(resp => {
                if (resp.status === 201) {
                    setMessage(resp.data.message)
                } else {
                    setError(resp.data.message)
                }

            })
            .catch(error => setError(error.message))

    }

    const submit = () => {

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => submitHandler()
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No'),

                }
            ]
        });
    }


    return (
        <div>
            <Header/>

            <div style={{padding: "5px 5px 20px 580px", backgroundColor: "#f5f5f5"}}>
                <div className="newLoanee">
                    <h1 className="addLoaneeTitle">Add Nomini To {user.username}</h1>

                    <form className="addLoaneeForm">

                        <div className="addLoaneeItem">
                            <label>Nomini Picture</label>

                            <input type="file"
                                onChange={(e) => setNominiPic(e.target.files[0])}
                            >
                            </input>
                        </div>
                        <div className="addLoaneeItem">
                            <label>Nomini-NID Picture</label>

                            <input type="file"
                                onChange={(e) => setNominiNidPic(e.target.files[0])}
                            >
                            </input>
                        </div>

                        <div className="addLoaneeItem">
                            <label>Nomini Name</label>
                            <input type="text" placeholder="Enter Nomini name"
                                   value={nominiName}
                                   onChange={(e) => setNominiName(e.target.value)}
                            />

                        </div>

                        <div className="addLoaneeItem">
                            <label>Nomini-Father Name</label>
                            <input type="text" placeholder="Enter nomini-father name"
                                   value={nominiFname}
                                   onChange={(e) => setNominiFname(e.target.value)}
                            />

                        </div>
                        <div className="addLoaneeItem">
                            <label>Nomini-Mother Name</label>
                            <input type="text" placeholder="Enter nomini-mother name"
                                   value={nominiMname}
                                   onChange={(e) => setNominiMname(e.target.value)}
                            />

                        </div>
                        <div className="addLoaneeItem">
                            <label>Nomini- DOB</label>
                            <input type="text" placeholder="yyyy-mm-dd"
                                   value={nominidob}
                                   onChange={(e) => setNominiDOB(e.target.value)}
                            />

                        </div>
                        <div className="addLoaneeItem">
                            <label>Nomini Relation</label>
                            <input type="text" placeholder="Enter Relation"
                                   value={nominiRel}
                                   onChange={(e) => setNominiRel(e.target.value)}
                            />

                        </div>

                    </form>
                </div>

                <button type="submit" className="addLoaneeButton"
                        onClick={submit}
                >
                    Add Nomini
                </button>
                {error && <MessageBox variant="danger">{error}</MessageBox>}
                {message && <MessageBox variant="success">{message}</MessageBox>}
            </div>

        </div>
    )
}

export default Nomini